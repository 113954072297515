<script setup lang="ts">
import SearchNotFoundIcon from '@/assets/svg/conversations/SearchNotFoundIcon.svg?component';
import SearchSourcingNotFoundIcon from '@/assets/svg/Icon-EmptyState-AO.svg?component';
import OutreachEmptyState from '@/assets/png/CAO-EmptyStateP.png';
import XCloseNotFound from '@/assets/svg/conversations/XCloseNotFound.svg?component';
import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import ProjectService from '@/core/shared/project/project.service';
import { JobApplicantStatus } from '@factoryfixinc/ats-interfaces';
import { computed } from 'vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName, TrackingCopilotSource } from '@/core/shared/tracking/tracking-actions';
import router from '@/router';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';

const projectService = new ProjectService();
const conversationIndexService = new ConversationIndexService();

const currentProject = computed(() => {
  return projectService.currentProject;
});
const copilotEnabled = computed(() => currentProject.value?.copilot);
const outreachEnabled = computed(() => currentProject.value?.activeOutreachEnabled);
const outreachStatusFilterSelected = computed(
  () => conversationIndexService.selectedStatusList?.includes(JobApplicantStatus.CONTACT) ?? false,
);
const copilotTrackingStatus = computed(() => {
  return currentProject.value?.copilot ? 'activated' : 'deactivated';
});
async function handleEditJob() {
  if (!currentProject.value) {
    SnackbarService.caution('There was an error while trying to edit the job.');
    return;
  }
  TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_STARTED, {
    source: TrackingCopilotSource.EDIT_JOB,
    copilot_status: copilotTrackingStatus.value,
    project_id: currentProject.value.id,
    job_id: currentProject.value.jobId,
  });
  const baseUrl = `/jobs/${currentProject.value.id}/copilot-activation`;
  router.push(baseUrl);
}
</script>

<template>
  <div>
    <div class="flex flex-col items-center justify-center gap-4 pt-16">
      <template v-if="outreachStatusFilterSelected">
        <template v-if="copilotEnabled && outreachEnabled">
          <div class="flex h-32 w-32 items-center justify-center rounded-full bg-tint-20">
            <SearchSourcingNotFoundIcon class="relative ml-[10px] text-tint-100" />
          </div>
          <div class="flex flex-col items-center gap-2">
            <h2 class="text-sm font-bold not-italic leading-5 text-shade-800">
              No candidates... yet!
            </h2>
            <h2 class="mx-8 text-center text-sm font-normal not-italic leading-5 text-shade-800">
              Copilot is currently scanning our pool of 2M+ skilled manufacturing candidates. Check
              back soon to see ideal matches for your open role.
            </h2>
          </div>
        </template>
        <template v-else-if="copilotEnabled && !outreachEnabled">
          <div class="flex flex-col items-center justify-center gap-2 px-6">
            <img :src="OutreachEmptyState" />
            <p class="mb-2 text-center font-serif text-2xl">
              Transform your recruiting with <br />
              <span class="font-extrabold">Active Outreach</span>
            </p>
            <p class="mb-4 text-center text-sm">
              Simply select <b>“Active Job Seekers + Passive Candidates”</b> under the sourcing
              options for this job to tap into our network of 2M+ professionals. Copilot will
              identify and engage ideal matches for your role.
            </p>
            <button
              class="rounded-lg bg-highlight-500 px-4 py-2.5 text-sm font-bold leading-[18px] text-white"
              @click="handleEditJob"
            >
              Edit job
            </button>
          </div>
        </template>
        <template v-else-if="!copilotEnabled">
          <div class="flex flex-col items-center justify-center gap-2 px-6">
            <img :src="OutreachEmptyState" />
            <p class="mb-2 text-center font-serif text-2xl">
              <span class="font-extrabold">Supercharge</span>
              <br />
              your recruiting
            </p>
            <p class="mb-4 text-center text-sm">
              Turn on Active Outreach to post this job and tap into a steady flow of passive
              candidates from our 2M+ talent pool
            </p>
            <button
              class="rounded-lg bg-highlight-500 px-4 py-2.5 text-sm font-bold leading-[18px] text-white"
              @click="handleEditJob"
            >
              Activate Copilot - Post Job
            </button>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="flex h-32 w-32 items-center justify-center rounded-full bg-tint-20">
          <SearchNotFoundIcon class="relative ml-[17px] text-tint-100" />
          <XCloseNotFound class="relative left-[-31.5%] mb-[3px] text-tint-100" />
        </div>
        <div class="flex flex-col items-center gap-2">
          <h2 class="text-sm font-bold not-italic leading-5 text-shade-900">No results found</h2>
          <h2 class="mx-8 text-center text-sm font-normal not-italic leading-5 text-shade-800">
            {{
              conversationIndexService.conversationIndexSearch.textual === ''
                ? 'Try changing your filter selections.'
                : 'Try using a different term'
            }}
          </h2>
        </div>
      </template>
    </div>
  </div>
</template>
