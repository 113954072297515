import { useAtsConversationApi, useAtsConversationApiWithOptions } from '@/composables/useApi';
import { useConversationMessageStore } from '@/core/conversations/message/conversation-message.store';
import {
  type ConversationMessage,
  type CreatedMessage,
  MessageMetadataType,
  type NewMessageDto,
} from '@/core/conversations/message/conversation-message.type';
import MeService from '@/core/shared/me/me.service';
import DateUtils from '@/utils/date-utils';
import { useConversationIndexStore } from '@/core/conversations/conversation-index/conversation-index.store';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import { ConversationMessagePersistence } from '@/core/conversations/message/conversation-message.persistence';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ApplicationScoreService from '@/core/conversations/application-score/application-score.service';
import ProjectService from '@/core/shared/project/project.service';
import type { UserMessageStatus } from './types/get-user-message-status.type';
import type { Employer } from '@factoryfixinc/ats-interfaces';

export default class ConversationMessageService {
  private readonly messageStore = useConversationMessageStore();
  private readonly conversationIndexStore = useConversationIndexStore();
  private readonly conversationMessagePersistence = new ConversationMessagePersistence();

  public get messagesPollingIntervalId(): number | undefined {
    return this.messageStore.messagesPollingIntervalId;
  }

  public set messagesPollingIntervalId(value: number | undefined) {
    this.messageStore.messagesPollingIntervalId = value;
  }

  public async fetchConversationMessages(
    conversationId: number,
    opts: { page: number; clearMessages?: boolean } = { page: 1 },
  ): Promise<ConversationMessage[]> {
    if (opts.clearMessages) {
      this.clearMessageList();
    }

    const messages = await this.conversationMessagePersistence.fetchConversationMessages(
      conversationId,
      opts.page,
    );

    const mappedMessages: ConversationMessage[] =
      messages?.map((message) => ({
        id: message.id,
        content: message.content,
        conversationId: message.conversationId,
        createdAt: DateUtils.dateStringToDate(message.createTs),
        sender: {
          id: message.sender?.senderId ?? null,
          name: message?.sender?.name ?? '',
        },
      })) ?? [];

    const scoreBlurbMessages = await this.mapApplicationScoreToMessages(
      conversationId,
      mappedMessages,
    );
    const applicationStatusMessages = await this.mapApplicantStatusHistory(conversationId);
    const allMessages = [...mappedMessages, ...scoreBlurbMessages, ...applicationStatusMessages];

    if (opts.clearMessages) {
      this.messageStore.setMessages(allMessages);
    } else {
      this.messageStore.pushMessages(allMessages);
    }

    const lastMessageId = this.messageStore.textOnlyMessages.at(-1)?.id;

    // limit mark read to first page only
    if (opts.page === 1 && lastMessageId) {
      this.markAsRead(lastMessageId);
    }

    return mappedMessages || [];
  }

  private async markAsRead(messageId: ConversationMessage['id']) {
    const isMessageAlreadyMarkedAsRead = messageId === this.messageStore.lastMessageMarkedAsRead;
    if (isMessageAlreadyMarkedAsRead) {
      return;
    }

    const url = `/message/${messageId}/change-read-status`;
    const { error } = await useAtsConversationApi(url).patch();

    if (error.value) {
      throw new Error(error.value);
    }

    this.messageStore.lastMessageMarkedAsRead = messageId;
    this.updateSelectedHeaderReadStatus();
  }

  public clearMessageList(): void {
    this.messageStore.clearMessageList();
    this.messageStore.lastMessageMarkedAsRead = undefined;
  }

  public async sendMessage(
    conversationId: number,
    message: string,
  ): Promise<CreatedMessage | undefined> {
    const meService = new MeService();
    const url = `/conversation/${conversationId}/message`;
    const payload: NewMessageDto = {
      message,
    };

    const { data, error } = await useAtsConversationApiWithOptions({ parseError: true })(url)
      .post(payload)
      .json<CreatedMessage>();

    if (error.value || !data.value) {
      if (error.value?.includes('cannot receive messages')) {
        SnackbarService.showSnackbar({
          text: '<span class="font-bold">Message not sent:</span> Candidate opted out of messaging',
          appendIcon: 'close',
          horizontalPosition: 'center',
          prependIcon: 'inform',
        });
        throw new Error(error.value);
      }
      throw new Error(error.value || 'Could not send a message');
    }

    const senderName = `${meService.userProfile?.nameFirst} ${meService.userProfile?.nameLast}`;
    const createdMessage = data.value;
    if (createdMessage) {
      this.messageStore.pushMessages([
        {
          content: createdMessage.content,
          conversationId,
          createdAt: createdMessage.createTs,
          sender: {
            id: createdMessage.senderId,
            name: senderName,
          },
          id: createdMessage.id,
        },
      ]);
      this.updateSelectedHeaderReadStatus(createdMessage.content);
      return data.value;
    }
  }

  private updateSelectedHeaderReadStatus(messageSent?: string) {
    const { selectedConversationIndex: selectedHeader } = this.conversationIndexStore;
    if (selectedHeader) {
      selectedHeader.readStatus = true;
      selectedHeader.lastMessageText = messageSent || selectedHeader.lastMessageText;
    }
  }

  public get messageList(): ConversationMessage[] {
    return this.messageStore.allMessages;
  }

  public async mapApplicationScoreToMessages(
    conversationId: number,
    messages: ConversationMessage[],
  ): Promise<ConversationMessage[]> {
    const proProfileService = new ProProfileService();
    const projectService = new ProjectService();
    const applicationScoreService = new ApplicationScoreService();
    const applications = proProfileService.selectedProProfile?.jobApplicants ?? [];

    const filteredApplications = applications
      // Filter applications that have a score, an applicant approval comment and
      // were scored after the new scoring release date.
      .filter((application) => {
        const newScoringRelease = new Date('2024-01-30').setHours(0, 0, 0, 0); // ONE-5
        const hasScore = typeof application.score === 'number';
        const applicationTs = application.submitTs ?? application.applyTs ?? application.createTs;
        const hasBlurb = !!application.applicantApprovalComment;

        if (!hasScore || !hasBlurb || !applicationTs) {
          return false;
        }

        const scoredTs = new Date(applicationTs).valueOf();

        return scoredTs >= newScoringRelease;
      });

    const scoreBlurbs: ConversationMessage[] = [];
    const applicationsScorePromises = await Promise.allSettled(
      filteredApplications.map((application) => {
        return applicationScoreService.getScoreDetail(application.id);
      }),
    );
    const applicationsScore = applicationsScorePromises.map((promise) => {
      return promise.status === 'fulfilled' ? promise.value : undefined;
    });

    // Map filtered applications to match the conversation message interface
    for (const application of filteredApplications) {
      const applicationTs = application.submitTs ?? application.applyTs ?? application.createTs;
      const scoredTs = applicationTs as string;
      const createdAt = DateUtils.dateStringToDate(scoredTs);
      const jobTitle = projectService.projectTitlesByJobId.get(application.jobId) ?? '';
      const scoreDetail = applicationsScore.find(
        (score) => score?.jobApplicationId === application.id,
      );
      const score = ApplicationScoreService.formatScore(scoreDetail?.score);
      const chips = ApplicationScoreService.getScoreChipsInfo(scoreDetail);
      const scoreVersion = scoreDetail?.version;

      scoreBlurbs.push({
        id: application.id,
        conversationId,
        content: scoreDetail?.summary ?? '',
        createdAt,
        metadata: {
          type: MessageMetadataType.SCORE_BLURB,
          score,
          chips,
          scoreVersion,
          jobTitle,
          applicationId: application.id,
        },
      });
    }

    // Ensure only scores that are newer than the oldest message are displayed (with a 5-minute buffer for Copilot Outreach scoring)
    const minCreatedAt = messages.reduce(
      (min, message) => (message.createdAt < min ? message.createdAt : min),
      new Date(),
    );
    const bufferTime = 5 * 60 * 1000; // 5 minutes
    const minCreatedAtWithBuffer = new Date(minCreatedAt.getTime() - bufferTime);

    return scoreBlurbs.filter((scoreBlurb) => {
      return scoreBlurb.createdAt >= minCreatedAtWithBuffer;
    });
  }

  public async mapApplicantStatusHistory(conversationId: number): Promise<ConversationMessage[]> {
    const proProfileService = new ProProfileService();

    return proProfileService.selectedProJobApplicantStatusHistory.map((status) => {
      const createdAt = DateUtils.dateStringToDate(status.createTs);
      return {
        id: status.id,
        conversationId,
        content: status.status,
        createdAt,
        sender: {
          id: status.creator?.id,
          name: [status.creator?.nameFirst, status.creator?.nameLast].filter(Boolean).join(' '),
        },
        metadata: {
          type: MessageMetadataType.APPLICATION_STATUS,
        },
      };
    });
  }

  public async getUserMessageStatus(
    userProfileId: number,
    employerId: Employer['id'],
  ): Promise<UserMessageStatus> {
    return this.conversationMessagePersistence.getUserMessageStatus(userProfileId, employerId);
  }
}
