import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import { InternalError } from '@/core/shared/errors/internal.error';
import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

export default (app: App): void => {
  if (import.meta.env.DEV) {
    return;
  }

  Sentry.init({
    app,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    replaysOnErrorSampleRate: 1.0,
    debug: true,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error instanceof InternalError) {
        if (error.status === HttpStatus.UNAUTHORIZED) {
          // do not send 401 errors to Sentry
          return null;
        }
      }
      return event;
    },
  });
};
