import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthenticationStore = defineStore(
  'authentication',
  () => {
    const accessToken = ref<string>();
    const accessTokenExpiresAt = ref<number>();
    const accessTokenRefreshTimerId = ref<NodeJS.Timeout>();

    const idToken = ref<string>();
    const idTokenExpiresAt = ref<number>();
    const idTokenRefreshTimerId = ref<NodeJS.Timeout>();

    function clearAccessTokenTimer(): void {
      if (accessTokenRefreshTimerId.value) {
        clearTimeout(accessTokenRefreshTimerId.value);
      }
      accessTokenRefreshTimerId.value = undefined;
    }

    function clearIdTokenTimer(): void {
      if (idTokenRefreshTimerId.value) {
        clearTimeout(idTokenRefreshTimerId.value);
      }
      idTokenRefreshTimerId.value = undefined;
    }

    function clear(): void {
      accessToken.value = undefined;
      accessTokenExpiresAt.value = undefined;
      idToken.value = undefined;
      idTokenExpiresAt.value = undefined;
      clearAccessTokenTimer();
      clearIdTokenTimer();
    }

    return {
      accessToken,
      accessTokenExpiresAt,
      accessTokenRefreshTimerId,
      idToken,
      idTokenExpiresAt,
      idTokenRefreshTimerId,
      clear,
      clearAccessTokenTimer,
      clearIdTokenTimer,
    };
  },
  { persist: true },
);
