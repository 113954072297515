import { InternalError } from '@/core/shared/errors/internal.error';
import { useAtsProjectsApi } from '@/composables/useApi';
import type { ProjectDistribution } from '@factoryfixinc/ats-interfaces';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';

export class ScheduledUpdatesPersistence {
  public async getScheduledUpdateByJobId(jobId: number): Promise<ProjectDistribution | undefined> {
    const url = `/scheduled-updates/${jobId}`;

    const { data, error, statusCode } = await useAtsProjectsApi(url)
      .get()
      .json<ProjectDistribution>();

    if (error.value) {
      // If there is an error, an InternalError is created with details about the error.
      const internalError = new InternalError(
        `Could not load Scheduled Updates for job ID ${jobId}`,
        {
          status: statusCode.value,
          error: error.value,
          data: { jobId },
        },
      );

      // If the error status is NOT_FOUND, the method returns undefined.
      if (internalError.status === HttpStatus.NOT_FOUND) {
        return undefined;
      }
      throw internalError;
    }
    return data.value ?? undefined;
  }
}
