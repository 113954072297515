export function stripHtml(html: string): string {
  if (!html) return '';
  const parser = new DOMParser();
  try {
    const doc = parser.parseFromString(html, 'text/html');
    const text = getTextContent(doc.body);
    return text.replace(/\n/g, ' ').replace(/\s\s+/g, ' ').trim();
  } catch (error) {
    return html
      .replace(/<[^>]*>/g, ' ')
      .replace(/\s\s+/g, ' ')
      .trim();
  }
}

function getTextContent(node: Node): string {
  const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, null);
  const textParts: string[] = [];
  let currentNode;
  while ((currentNode = walker.nextNode())) {
    textParts.push(currentNode.textContent?.trim() || '');
  }
  return textParts.join(' ');
}
