<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  isSelected: boolean;
  badgeContent?: string;
}>();

const isRecommended = computed(
  () =>
    typeof props.badgeContent === 'string' && props.badgeContent.toUpperCase() === 'RECOMMENDED',
);
</script>
<template>
  <div
    class="flex flex-1 cursor-pointer flex-col gap-2 rounded-2xl border border-tint-300 p-4"
    :class="{
      '!border-highlight-500 bg-highlight-0': isSelected,
      'text-shade-820': !isSelected,
    }"
  >
    <div>
      <div class="flex flex-row gap-3">
        <div class="flex-1 text-base font-bold"><slot name="title">title</slot></div>
        <div
          v-if="badgeContent"
          class="self-center rounded px-1.5 py-1 text-2xs font-bold leading-tight text-white"
          :class="{
            'bg-highlight-500': isRecommended,
            'bg-shade-900': !isRecommended,
          }"
        >
          {{ badgeContent }}
        </div>
      </div>
    </div>
    <div class="text-sm">
      <slot></slot>
    </div>
    <div>
      <slot name="action"></slot>
    </div>
  </div>
</template>
<style></style>
