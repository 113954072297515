<script setup lang="ts">
import { computed, watch } from 'vue';
import { VTextField } from 'vuetify/components';
import { debounce, sleep } from 'radash';

import ChevronRightDoubleSvg from '@/assets/svg/chevron-right-double-20.svg?component';
import ChevronLeftDoubleSvg from '@/assets/svg/chevron-left-double-20.svg?component';
import SearchSvg from '@/assets/svg/search-20.svg?component';
import XCloseIcon from '@/assets/svg/conversations/XCloseIcon.svg?component';
import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import { DrawerService } from '@/core/shared/drawer/drawer.service';
import { ref } from 'vue';

const drawerService = new DrawerService();
const conversationIndexService = new ConversationIndexService();

const isFirstRun = ref(true);

const isProjectsDrawerOpen = computed(() => drawerService.isProjectsDrawerOpen);

const _collapseProjectsIconLabel = computed(() =>
  drawerService.isProjectsDrawerOpen ? 'Hide jobs' : 'Show jobs',
);
const collapseProjectsIconLabel = ref(_collapseProjectsIconLabel.value);

const searchText = computed({
  get: () => conversationIndexService.searchText,
  set: (value) => {
    conversationIndexService.searchText = value;
  },
});

const showSearchField = computed({
  get: () => conversationIndexService.showSearchField,
  set: (value) => {
    conversationIndexService.showSearchField = value;
  },
});

const searchConversationIndexesDebounced = debounce({ delay: 500 }, () => {
  if (isFirstRun.value) {
    isFirstRun.value = false;
    return;
  }

  conversationIndexService.resetConversationIndexes();
  conversationIndexService.updateConversationIndexSearch(
    { textual: searchText.value },
    { resetPagination: true },
  );
});

const clearText = () => {
  searchText.value = '';
  showSearchField.value = false;
};

const onInputFocus = (isFocused: boolean) => {
  if (!isFocused && searchText.value === '') {
    showSearchField.value = false;
  }
};

watch(
  searchText,
  () => {
    searchConversationIndexesDebounced();
  },
  {
    immediate: false,
    flush: 'post',
  },
);

// Ensure the collapse icon label changes predictably
watch(isProjectsDrawerOpen, async () => {
  await sleep(300); // Wait for drawer to close
  collapseProjectsIconLabel.value = _collapseProjectsIconLabel.value;
});
</script>

<template>
  <div class="h-[34px] w-full">
    <template v-if="showSearchField">
      <div class="relative w-full">
        <v-text-field
          autofocus
          placeholder="Search..."
          v-model="searchText"
          class="text-pro-field__input"
          density="compact"
          variant="solo"
          clearable
          persistent-clear
          @update:focused="onInputFocus"
        >
          <template v-slot:prepend-inner>
            <SearchSvg class="text-shade-900" />
          </template>
          <template v-slot:clear>
            <XCloseIcon class="text-shade-900" @click="clearText" />
          </template>
        </v-text-field>
      </div>
    </template>

    <template v-else>
      <div class="flex h-8 items-center justify-between">
        <v-tooltip location="bottom" content-class="rounded-tooltip tooltip-top-arrow">
          <template #activator="{ props }">
            <div
              v-bind="props"
              class="ml-1 cursor-pointer"
              @click="drawerService.toggleProjectsDrawer"
            >
              <ChevronLeftDoubleSvg v-if="drawerService.isProjectsDrawerOpen" />
              <ChevronRightDoubleSvg v-else />
            </div>
          </template>
          <span class="text-xs">{{ collapseProjectsIconLabel }}</span>
        </v-tooltip>

        <div class="ml-2 flex flex-1 justify-between">
          <h2
            class="w-[70%] self-center p-0 font-serif text-base font-black leading-6 text-shade-900"
          >
            Candidates
          </h2>
          <div
            class="flex cursor-pointer items-center rounded-md px-2 py-1 transition-colors hover:bg-tint-40"
            @click="showSearchField = true"
          >
            <span class="mr-1 text-xs font-semibold text-shade-900">Search</span>
            <SearchSvg />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="postcss">
.text-pro-field__input {
  & .v-field {
    @apply box-border border border-solid border-highlight-500 pr-1 shadow-none !important;
  }
  & .v-field--variant-solo {
    @apply shadow-none !important;
  }
  & input.v-field__input {
    @apply h-8 min-h-0 bg-white p-0 pl-2 text-sm text-shade-900 placeholder:text-tint-300 placeholder:opacity-100 !important;
  }
  & .v-input__details {
    @apply hidden !important;
  }
}
</style>
