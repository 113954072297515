<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.svg?component';
import VersionsService from '@/core/jobs/versions/versions.service';
import type { JobVersionHistory } from '@/core/jobs/versions/types/job-version-history.type';
defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const jobVersionsService = new VersionsService();
const jobVersions = computed(() => jobVersionsService.jobVersions || []);

const selectedJobVersion = ref<JobVersionHistory>(jobVersions.value[0]);

const itemProps = (item: JobVersionHistory) => {
  return {
    title: VersionsService.formatJobVersionDate(new Date(item.createTs)),
    id: item.id,
  };
};
function updateJobVersionIdSelection(newJobVersion: JobVersionHistory) {
  if (jobVersionsService.currentJobVersionId === newJobVersion.id) return;
  jobVersionsService.currentJobVersionId = newJobVersion.id;
}
onMounted(() => {
  if (selectedJobVersion.value) {
    jobVersionsService.currentJobVersionId = selectedJobVersion.value.id;
  }
});
</script>
<template>
  <div>
    <v-select
      v-model="selectedJobVersion"
      :items="jobVersions"
      :item-props="itemProps"
      variant="outlined"
      density="compact"
      :menu-icon="ChevronIcon"
      :menu-props="{
        contentClass: 'pt-1',
      }"
      class="v-select--selected"
      @update:model-value="updateJobVersionIdSelection"
      :disabled="isLoading"
    >
      <template v-slot:selection="{ item }">
        <span class="font-bold">Version:&nbsp;</span> {{ item.title }}
      </template>
      <template v-slot:item="{ props, item }">
        <div
          v-bind="props"
          class="select-item"
          :class="{
            'bg-highlight-50 font-bold': item.raw.id === selectedJobVersion?.id,
          }"
        >
          <span><span class="font-bold">Version:</span> {{ item.title }}</span>
        </div>
      </template>
    </v-select>
  </div>
</template>

<style scoped lang="postcss">
:deep(.v-field) {
  border-radius: 4px;

  .v-field__input {
    @apply text-xs;
  }
  .v-field__outline {
    &__start,
    &__end {
      opacity: 1;
      @apply border-y border-tint-80;
    }
  }

  &--focused {
    .v-field__outline {
      opacity: 1;
      &__start {
        @apply border-l border-highlight-500;
      }
      &__end {
        @apply border-r border-highlight-500;
      }
    }
  }
}
.select-item {
  @apply mb-0.5 flex h-8 min-h-[40px] cursor-pointer items-center justify-start px-3 py-1 text-sm hover:bg-tint-20;
}
</style>
