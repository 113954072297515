import type { ProjectDistribution } from '@factoryfixinc/ats-interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useScheduledUpdatesStore = defineStore(
  'scheduledUpdates',
  () => {
    // Loading
    const isLoadingUpdate = ref<boolean>(false);

    //Data
    const currentScheduledUpdate = ref<ProjectDistribution | null>(null);
    return {
      isLoadingUpdate,
      currentScheduledUpdate,
    };
  },
  {
    persist: false,
  },
);
