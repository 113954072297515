<script setup lang="ts">
import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.svg?component';
import { computed, nextTick, onBeforeMount, onMounted, ref } from 'vue';

const emit = defineEmits<{
  (e: 'click:back'): void;
}>();

const headers = [
  {
    text: 'Job details',
    tag: 'job-details',
  },
  {
    text: 'Skills required',
    tag: 'must-have-skills',
  },
  {
    text: 'Job description',
    tag: 'job-description',
  },
  {
    text: 'Screening questions',
    tag: 'screening-questions',
  },
];

const defaultAnchorTag = ref('#job-details');
const headersScrollObserver = ref<IntersectionObserver | null>(null);

const currentSelectedAnchorTag = computed(() => defaultAnchorTag.value);

function handleBackClick() {
  emit('click:back');
}

function navigateToHeaderTag(tag?: string) {
  const hashedTag = tag ? `#${tag}` : currentSelectedAnchorTag.value;

  nextTick(() => {
    const element = document.querySelector(hashedTag);

    if (element) {
      const offset = 64;
      const topOffset = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  });
}

function startHeaderItemsIntersectionObserver() {
  const observerOptions = {
    root: null, // relative to document viewport
    rootMargin: '0px',
    threshold: 0.5, // trigger when 50% of the element is in view
  };

  const observerCallback: IntersectionObserverCallback = (entries) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const sectionId = entry.target.id;
        const header = headers.find((header) => header.tag === sectionId);

        if (header) {
          defaultAnchorTag.value = `#${header.tag}`;
        }
      }
    }
  };

  headersScrollObserver.value = new IntersectionObserver(observerCallback, observerOptions);

  for (const header of headers) {
    const sectionElement = document.getElementById(header.tag);

    if (sectionElement) {
      headersScrollObserver.value.observe(sectionElement);
    }
  }
}

onMounted(() => {
  navigateToHeaderTag();
  startHeaderItemsIntersectionObserver();
});

onBeforeMount(() => {
  if (headersScrollObserver.value) {
    headersScrollObserver.value.disconnect();
  }
});
</script>

<template>
  <div>
    <div class="mb-6 h-6 w-20 cursor-pointer leading-6" @click="handleBackClick">
      <ChevronIcon class="mr-2 inline-block h-6 w-6 rotate-90 align-top" />
      <span class="inline-block align-top text-sm font-normal leading-6">Back</span>
    </div>

    <p class="mb-1 px-2 py-1.5 font-serif text-base font-black leading-5">Activate Copilot</p>

    <a
      v-for="header in headers"
      :key="header.tag"
      class="mb-1 flex cursor-pointer scroll-smooth px-2 py-1.5 text-sm font-normal leading-5 hover:font-bold"
      :class="{
        'rounded-md bg-shade-840 !font-bold': currentSelectedAnchorTag === `#${header.tag}`,
      }"
      @click.prevent="navigateToHeaderTag(header.tag)"
    >
      <span>{{ header.text }}</span>
    </a>
  </div>
</template>
