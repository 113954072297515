import type { ProjectDistribution } from '@factoryfixinc/ats-interfaces';
import { ScheduledUpdatesPersistence } from './scheduled-updates.persistence';
import { useScheduledUpdatesStore } from './scheduled-updates.store';

export default class ScheduledUpdatesService {
  private store = useScheduledUpdatesStore();
  private persistence = new ScheduledUpdatesPersistence();

  public get isLoadingUpdate(): boolean {
    return this.store.isLoadingUpdate;
  }

  public get currentScheduledUpdate(): ProjectDistribution | null {
    return this.store.currentScheduledUpdate;
  }
  public set currentScheduledUpdate(currentScheduledUpdate: ProjectDistribution | null) {
    this.store.currentScheduledUpdate = currentScheduledUpdate;
  }

  public async loadScheduledUpdateByJobId(jobId: number): Promise<void> {
    this.store.isLoadingUpdate = true;
    try {
      const scheduledUpdate = await this.persistence.getScheduledUpdateByJobId(jobId);
      this.store.currentScheduledUpdate = scheduledUpdate ?? null;
    } catch (error) {
      throw error;
    } finally {
      this.store.isLoadingUpdate = false;
    }
  }
}
