<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { type LocationQuery, useRoute, useRouter } from 'vue-router';
import { debounce } from 'radash';
import ProjectSearchFiltersDialog from '@/components/Shared/Projects/ProjectSearchFiltersDialog.vue';
import FilterLinesSvg from '@/assets/svg/filter-lines-20.svg?component';
import { ProjectSource, ProjectStatus } from '@factoryfixinc/ats-interfaces';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import MeService from '@/core/shared/me/me.service';
import ProjectService from '@/core/shared/project/project.service';

const emit = defineEmits<{
  (e: 'change:filters'): void;
}>();

const meService = new MeService();
const projectService = new ProjectService();
const route = useRoute();
const router = useRouter();
const isProjectSearchFiltersDialogOpen = ref<boolean>(false);
const searchQuery = computed(() => route.query);
const filtersCount = computed(() => projectService.filtersCount);

async function setTitleOrReqIdQuery(event: Event) {
  const searchText = event.target as HTMLInputElement;
  const query = { ...searchQuery.value };

  if (searchText.value) {
    query.titleOrReqId = searchText.value;
  } else {
    delete query.titleOrReqId;
  }

  if (projectService.copilot) {
    query.copilot = String(projectService.copilot);
  }
  if (projectService.locations?.length) {
    query.locations = projectService.locations;
  }
  if (projectService.status) {
    query.status = projectService.status;
  }
  if (projectService.teamIds?.length) {
    query.teamIds = projectService.teamIds.map(String);
  }
  if (projectService.source) {
    query.source = projectService.source;
  }

  await router.push({ query });
}

function openProjectSearchFiltersDialog() {
  isProjectSearchFiltersDialogOpen.value = true;
}

/**
 * Sets the project filters based on the query from the URL
 * @param storageQueryData if provided, will use this data to set the project filters instead of the query from the URL
 */
async function setProjectFilters(storageQueryData: LocationQuery | null = null) {
  const query = storageQueryData ? { ...storageQueryData } : { ...searchQuery.value };

  // Title or Req ID
  if (query.titleOrReqId) {
    projectService.titleOrReqId = query.titleOrReqId as string;
  }

  // Copilot
  if (query.copilot) {
    // Need to be sure this its a string: query params is a string but localStorage is a boolean
    if (String(query.copilot) === 'true') {
      projectService.copilot = true;
    } else {
      projectService.copilot = null;
    }
  }
  // Source
  if (query.source) {
    projectService.source = query.source as ProjectSource;
  }

  // Location
  if (query.location) {
    if (typeof query.location === 'string') {
      projectService.locations = [query.location];
    }

    if (Array.isArray(query.location)) {
      projectService.locations = query.location as string[];
    }
  }
  // Locations
  if (query.locations) {
    if (typeof query.locations === 'string') {
      projectService.locations = [query.locations];
    }

    if (Array.isArray(query.locations)) {
      projectService.locations = query.locations as string[];
    }
  }
  // Status
  if (query.status) {
    if (query.status === ProjectStatus.LIVE) {
      projectService.status = ProjectStatus.LIVE;
    } else {
      projectService.status = null;
    }
  }
  // Team Ids
  if (query.teamIds) {
    if (typeof query.teamIds === 'string') {
      projectService.teamIds = [Number(query.teamIds)];
    }

    if (Array.isArray(query.teamIds)) {
      projectService.teamIds = query.teamIds.map(Number);
    }
    projectService.didSelectUserInProjectFilters = true;
  } else {
    selectOwnUserOnce();
  }

  const queryToSave: Pick<
    ProjectService,
    'copilot' | 'locations' | 'status' | 'source' | 'teamIds'
  > = {
    copilot: projectService.copilot,
    locations: projectService.locations,
    // We need to save status as REMOVED because the `null` value skips and assigns `LIVE` as default
    status: projectService.status ? projectService.status : ProjectStatus.REMOVED,
    source: projectService.source,
    teamIds: projectService.teamIds,
  };
  // Save queryToSave to localStorage to persist filters
  localStorage.setItem(
    `search-query-${currentUserAndEmployerId.value}`,
    JSON.stringify(queryToSave),
  );
  emit('change:filters');
}

function selectOwnUserOnce() {
  if (projectService.didSelectUserInProjectFilters) {
    return;
  }

  projectService.didSelectUserInProjectFilters = true;
  const isOwnUserProfileInTeamList = meService.employerUsers?.some(
    (user) => user.id === meService.userProfile?.id,
  );

  if (!isOwnUserProfileInTeamList) {
    return;
  }

  const ownUserProfileId = meService.userProfile?.id as number;
  projectService.teamIds = [ownUserProfileId];
}

const debouncedSetTitleOrReqIdQuery = debounce({ delay: 500 }, setTitleOrReqIdQuery);

watch(searchQuery, async () => {
  await setProjectFilters();
});

onMounted(async () => {
  try {
    await Promise.all([projectService.getEmployerLocations()]);
    await setProjectFilters(getLocalStorageQuery());
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load filters');
  }
});

function getLocalStorageQuery() {
  const queryLocalStorage = localStorage.getItem(`search-query-${currentUserAndEmployerId.value}`);
  return queryLocalStorage ? JSON.parse(queryLocalStorage) : null;
}
const currentUserAndEmployerId = computed(
  () => `${meService.userProfile?.id}-${meService.employer?.id}`,
);
</script>

<template>
  <div>
    <!-- Title Input -->
    <div
      class="relative flex h-[40px] items-center rounded-md bg-shade-840 p-2"
      data-test-id="project-filter-section"
    >
      <input
        v-model="projectService.titleOrReqId"
        type="text"
        placeholder="Search by job name or ATS Req ID"
        class="flex-1 bg-shade-840 text-sm focus:outline-none"
        @input="debouncedSetTitleOrReqIdQuery"
        data-test-id="project-search-text-filter"
      />
    </div>

    <!-- Filters Menu Button -->
    <button
      class="mt-2 inline-flex items-center rounded-md border px-2 py-1"
      @click="openProjectSearchFiltersDialog"
    >
      <!-- Label -->
      <div class="text-xs font-semibold leading-4">Filters</div>
      <!-- Icon w/ Count -->
      <div class="relative ml-1 cursor-pointer">
        <FilterLinesSvg class="stroke-white" />
        <div
          v-if="filtersCount"
          class="absolute right-[-4px] top-[-2px] h-[12px] w-[12px] rounded-full bg-highlight-600"
        >
          <div class="flex items-center justify-center">
            <span class="m-[-1.5px] text-2xs font-bold">{{ filtersCount }}</span>
          </div>
        </div>
      </div>

      <ProjectSearchFiltersDialog v-model="isProjectSearchFiltersDialogOpen" />
    </button>
  </div>
</template>
