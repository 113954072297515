import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(advancedFormat);

export default class DateUtils {
  public static formatTimestamp(timestamp: string | Date, format: string): string {
    return dayjs(timestamp).format(format);
  }

  public static yearMonthToDate(yearMonthText?: string): Date | null {
    if (!yearMonthText) {
      return null;
    }
    return dayjs(yearMonthText, 'YYYY-MM').startOf('month').toDate();
  }

  public static getHumanizedDuration(startDate?: Date, endDate?: Date): string {
    if (!startDate || !endDate) {
      return '';
    }
    return dayjs.duration(dayjs(startDate).diff(dayjs(endDate))).humanize();
  }

  public static dateStringToDate(
    dateString: string | Date,
    parseFormat = 'YYYY-MM-DDTHH:mm:ss',
  ): Date {
    return dayjs(dateString, parseFormat).toDate();
  }

  public static age(date: Date = new Date()): duration.Duration {
    return dayjs.duration(dayjs().diff(dayjs(date)));
  }

  /**
   * only shows time if the date is today.
   * shows date and time if the date is not today.
   * @param messageDate
   */
  public static formatTimeAndDateIfNotToday(messageDate?: Date | string): string {
    if (!messageDate) return '';
    const today = new Date();
    const msgDate = new Date(messageDate);

    // Extract the year, month, and day from today and messageDate
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();
    const todayDay = today.getDate();

    const msgYear = msgDate.getFullYear();
    const msgMonth = msgDate.getMonth();
    const msgDay = msgDate.getDate();

    // Check if the message date is not today
    if (todayYear !== msgYear || todayMonth !== msgMonth || todayDay !== msgDay) {
      // The dates are from different days
      if (todayYear !== msgYear) {
        // Message is from a different year, include the year in the format
        return DateUtils.formatTimestamp(messageDate, 'MMM DD, YYYY hh:mma');
      } else {
        // Message is from the current year but a different day, exclude the year
        return DateUtils.formatTimestamp(messageDate, 'MMM DD hh:mma');
      }
    }

    // The dates are from the same day
    return DateUtils.formatTimestamp(messageDate, 'hh:mma');
  }

  public static timeAgo(date: Date | string): string {
    return dayjs(date).fromNow();
  }
  public static isSame(date: Date | string, range: 'day' | 'month' | 'year' = 'day'): boolean {
    return dayjs().isSame(date, range);
  }
  public static calculateDays(date: Date | string): string {
    if (!dayjs(date).isValid()) return 'Invalid date provided';
    const days = Math.abs(dayjs().diff(date, 'days'));
    return days === 0 ? 'today' : days === 1 ? '1 day' : `${days + 1} days`;
  }
  /**
   * Formats a date string using dayjs format tokens
   * @see https://day.js.org/docs/en/display/format for available format tokens
   * @param dateString The date to format
   * @param parseFormat The desired output format (default: 'MMMM D, YYYY')
   * @returns Formatted date string or 'Invalid date provided' if the input is invalid
   */
  public static formatDate(dateString: string | Date, parseFormat = 'MMMM D, YYYY'): string {
    if (!dayjs(dateString).isValid()) return 'Invalid date provided';
    return dayjs(dateString).format(parseFormat);
  }
}
