<script setup lang="ts">
import { type PropType, computed } from 'vue';

import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.svg?component';
import PlusIcon from '@/assets/svg/plus-16.svg?component';

const props = defineProps({
  modelValue: Array as PropType<Array<number>>,
  items: {
    type: Array as PropType<Array<{ id: number; title: string }>>,
    required: true,
  },
  suggestions: {
    type: Array as PropType<Array<number>>,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: 'Select',
  },
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: Array<unknown>): void;
}>();

const localValue = computed(() => props.modelValue);
const localItems = computed(() => props.items);
const suggestions = computed(() => props.suggestions);
const placeholderText = computed(() => props.placeholder);

const availableSuggestions = computed(() => {
  const availableSuggestionIds = suggestions.value.filter((suggestion) => {
    return !localValue.value?.includes(suggestion);
  });

  return localItems.value.filter((item) => {
    return availableSuggestionIds.includes((item as { id: number }).id);
  });
});
const hasAvailableSuggestions = computed(() => availableSuggestions.value.length > 0);

function handleUpdateModelValue(value: Array<unknown>) {
  emit('update:modelValue', value);
}

function selectSuggestion(suggestionId: number) {
  let newValue = [suggestionId];

  if (Array.isArray(localValue.value)) {
    newValue = [...localValue.value, suggestionId];
  }
  handleUpdateModelValue(newValue);
}

function removeSuggestion(suggestionId: number) {
  if (Array.isArray(localValue.value)) {
    const newValue = localValue.value.filter((id) => id !== suggestionId);
    handleUpdateModelValue(newValue);
  }
}
</script>

<template>
  <div>
    <v-autocomplete
      v-model:model-value="localValue"
      :items="localItems"
      item-title="title"
      item-value="id"
      variant="outlined"
      density="comfortable"
      multiple
      hide-details
      :placeholder="placeholderText"
      :return-object="false"
      :menu-icon="ChevronIcon"
      :menu-props="{ maxWidth: '536px' }"
      @update:model-value="handleUpdateModelValue"
    >
      <template v-slot:selection="data">
        <div
          class="flex h-8 cursor-pointer content-center rounded-full border border-inform-200 bg-inform-100 px-3 py-1.5"
          @click.stop="removeSuggestion(data.item.value)"
        >
          <span class="box-border inline-block h-5 text-sm leading-5">
            {{ data.item.title }}
          </span>
          <PlusIcon class="ml-1.5 mt-0.5 inline-block h-4 rotate-45 text-black" />
        </div>
      </template>
    </v-autocomplete>

    <div v-if="hasAvailableSuggestions" class="suggestions">
      <p class="my-4 font-sans text-sm font-normal leading-[18px] text-shade-800">Suggestions</p>
      <div class="flex flex-wrap gap-1.5">
        <div
          v-for="suggestion in availableSuggestions"
          :key="suggestion.id"
          class="box-border flex h-8 cursor-pointer content-center rounded-full border border-tint-80 px-3 py-1.5"
          @click="selectSuggestion(suggestion.id)"
        >
          <span class="inline-block h-5 text-sm leading-5">
            {{ suggestion.title }}
          </span>
          <PlusIcon class="ml-1.5 mt-0.5 inline-block h-4 text-black" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-input :deep(.v-field),
.v-input--horizontal :deep(.v-field) {
  border-radius: 6px;

  .v-combobox__selection {
    @apply h-[32px];
  }

  .v-field__outline {
    @apply text-tint-80;
  }
  input {
    @apply font-sans text-sm font-normal leading-[21px] text-shade-880;
  }
  input::placeholder {
    @apply text-shade-800;
    opacity: 1;
  }

  &:hover {
    .v-field__outline {
      @apply text-shade-840;
    }
  }
}

.v-input :deep(.v-field.v-field--focused),
.v-input--horizontal :deep(.v-field.v-field--focused) {
  .v-field__outline {
    @apply text-highlight-500;
  }
}

.v-combobox :deep(.v-field__input) {
  @apply py-2;
}
</style>
